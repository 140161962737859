<template>
  <div class="index_wrap" @scrol="handleScroll" >
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in bannerList" :key="index" >
        <img :src="item.picUrl" class="swiper_img" />
      </van-swipe-item>
    </van-swipe>
    <div class="content_wrap" >
      <div class="category_list" >
        <div class="category_item" @click="changeCate(-1)">
          <!--          <img src="./img/1.png" class="category_checked" />-->
          <div :class="current == -1 ? 'category_active' : 'category_bg'" >全部</div>
        </div>
        <div class="category_item" v-for="(item, index) in cateList" :key="index"  @click="changeCate(index)">
<!--          <img src="./img/1.png" class="category_checked" />-->
          <img :src="item.picUrl" class="category_img" />
          <div :class="current == index ? 'category_active' : 'category_bg'" >{{ item.name }}</div>
        </div>
      </div>

      <div class="goods_list_wrap" >
        <div class="goods_item" v-for="(item, index) in list" :key="index" @click="toDetail(item.storeProductId)" >
          <img :src="item.image" class="goods_img" />
          <div class="goods_name" >{{ item.storeName }}</div>
          <div class="goods_opt" >
            <div class="goods_price" >
              <span>￥</span>
              <div>{{ item.price }}</div>
<!--              <span>.99</span>-->
            </div>
            <div class="cart_button" @click.stop="addCart(item)" >
              <img src="./img/2.png" class="cart_icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-empty image="search" description="暂无商品" v-if="list.length <= 0" />
    <div class="bottom_wrap" >
      <div class="bottom_left" >
        <van-badge :content="count" max="99">
          <img src="./img/3.png" class="cart_img" @click="$refs.cart.handleShow(cartData)" />
        </van-badge>
        <div class="line" ></div>
        <div class="price_wrap" >
          <div class="total_price" >
            <span>￥</span>
            {{ total }}
          </div>
          <div class="freight_money_text" >
            运费 {{ cartData.length > 0 ? freight : 0 }}元
          </div>
        </div>
      </div>
      <van-button class="buy_button" @click="toConfirmOrder" >立即结算</van-button>
    </div>
    <shopping-cart ref="cart" @close="cartClose" />
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCategoryList,
  getBannerVoList,
  getGoodsList,
  getShoppingFeeVo
} from '@/api'
import {
  Toast
} from 'vant'
export default {
  name: 'index',
  data() {
    return  {
      cateList: [],
      current: -1,
      bannerList: [],
      page: 1,
      pageSize: 20,
      list: [],
      total: 0,
      freight: 0,
      cartData: !sessionStorage.getItem('cartData') ? [] : JSON.parse(sessionStorage.getItem('cartData')),
      config: {
        count: 0,
        minFee: 0,
        maxFee: 0
      },
      count: 0
    }
  },
  methods: {
    toConfirmOrder() {
      if(this.cartData.length <= 0) {
        Toast('请添加下单商品')
        return false
      }
      this.$router.push({
        name: 'confirmOrder'
      })
    },
    cartClose(data) {
      this.cartData = data
      sessionStorage.setItem('cartData', JSON.stringify(data))
      this.getTotal()
    },
    addCart(item) {
      const { cartData } = this
      let data = JSON.parse(JSON.stringify(item))
      data.num = 1
      let index = cartData.findIndex(item => {  return item.storeProductId == data.storeProductId})
      if(index > - 1) {
        cartData[index].num ++
      }else {
        cartData.push(data)
      }
      sessionStorage.setItem('cartData', JSON.stringify(cartData))
      this.cartData = cartData
      this.getTotal()
    },
    getTotal() {
      const { cartData, config } = this
      let total = 0
      let count = 0
      cartData.map(item => {
        total += parseFloat(item.price) * item.num
        count += item.num
      })
      this.count = count
      this.total = total.toFixed(2)
      this.freight = count > config.count ? config.maxFee : config.minFee
    },
    toDetail(storeProductId) {
      this.$router.push({
        name: 'goodsDetail',
        params: {
          storeProductId
        }
      })
    },
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        this.page++
        this.getGoodsList()
      }
    },
    changeCate(current) {
      if(current == this.current) {
        return false
      }
      this.current = current
      this.reSearch()
    },
    reSearch() {
      this.list = []
      this.page = 1
      this.getGoodsList()
    },
    getCategoryList() {
      getCategoryList({}).then(res => {
        if(res.success) {
          this.cateList = res.data
          this.getGoodsList()
        }
      })
    },
    getBannerVoList() {
      getBannerVoList({}).then(res => {
        if(res.success) {
          this.bannerList = res.data
        }
      })
    },
    getGoodsList() {
      const { page, pageSize } = this
      getGoodsList({ page, pageSize, categoryId: this.current == -1 ? '':  this.cateList[this.current].categoryId }).then(res => {
        if(res.success) {
          this.list = this.list.concat(res.data)
          this.getTotal()
        }
      })
    },
    getShoppingFeeVo() {
      getShoppingFeeVo({}).then(res => {
        if(res.success) {
          this.config = res.data
        }
      })
    }
  },
  mounted() {
    this.getCategoryList()
    this.getShoppingFeeVo()
    this.getBannerVoList()
  }
}
</script>
