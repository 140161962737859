<template>
  <div class="index_wrap" >
    <div class="user_wrap" >
<!--      <div class="user_img_wrap" style="visibility: hidden">
        <img src="./img/15.png" />
      </div>-->
      <div class="user_content" v-if="user" >
        <div class="username" >
          <div class="username" >{{ user.name }}</div>
          <div class="level_wrap" >
            <img src="./img/2.png" class="level_icon" />
            {{ user.lvlStr }}
          </div>
        </div>
        <div class="user_phone" >{{ user.phone }}</div>
      </div>
    </div>
    <div class="order_wrap" >
      <div class="order_item" @click="toOrder('')" >
        <img src="./img/3.png" class="order_icon" />
        全部
      </div>

      <div class="order_item" @click="toOrder('1')"  >
        <img src="./img/4.png" class="order_icon" />
        待发货
      </div>

      <div class="order_item" @click="toOrder('2')"  >
        <img src="./img/5.png" class="order_icon" />
        已发货
      </div>

      <div class="order_item" @click="toOrder('4')"  >
        <img src="./img/6.png" class="order_icon" />
        已退款
      </div>
    </div>
    <div class="content_wrap" >
      <div class="account_wrap" >
        <div class="account_top" >
          <div>账户余额</div>
          <div class="account_log" @click="toPage('accountLog')" >
            账户记录
            <img src="./img/8.png" class="enter_icon" />
          </div>
        </div>
        <div class="account_bottom" >
          <div class="user_balance" >
            <span>￥</span>
            {{ amount }}
          </div>
          <div class="cash_button" @click="$refs.cash.handleShow()" >提现</div>
        </div>
      </div>

      <div class="panel_item" @click="toPage('team')" >
        <div class="panel_left" >
          <img src="./img/17.png" class="panel_icon" />
          我的团队
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="toPage('device')" >
        <div class="panel_left" >
          <img src="./img/9.png" class="panel_icon" />
          我的设备
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="toPage('deviceOrder')" >
        <div class="panel_left" >
          <img src="./img/10.png" class="panel_icon" />
          设备订单
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="toPage('address')" >
        <div class="panel_left" >
          <img src="./img/11.png" class="panel_icon" />
          收货地址
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="toPage('bank')" >
        <div class="panel_left" >
          <img src="./img/12.png" class="panel_icon" />
          银行卡
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="toPage('cashList')" >
        <div class="panel_left" >
          <img src="./img/12.png" class="panel_icon" />
          提现记录
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="panel_item" @click="$refs.update.handleShow()">
        <div class="panel_left" >
          <img src="./img/13.png" class="panel_icon" />
          修改密码
        </div>
        <img src="./img/14.png" class="enter_icon" />
      </div>

      <div class="logout" @click="handleLogout" >退出登录</div>
    </div>

    <update-pwd ref="update"  />

    <cash-dialog ref="cash" :amount="parseFloat(amount)" @ok="getAccount" />
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getUserVo,
  getAccount
} from '@/api'
import { Dialog } from 'vant'
import updatePwd from "./component/UpdatePwd";
import CashDialog from "./component/CashDialog";
export default {
  name: 'user',
  data() {
    return {
      user: null,
      amount: 0,
      show: true
    }
  },
  components: { updatePwd, CashDialog },
  methods: {
    handleLogout() {
      Dialog.confirm({
        title: '提示',
        message: '是否退出登录？',
        confirmButtonColor: '#03CFAF',
      })
        .then(() => {
          // on confirm
          localStorage.clear()
          window.location.reload()
        })
        .catch(() => {
          // on cancel
        });
    },
    toOrder(status) {
      this.$router.push({
        name: 'order',
        query: {
          status
        }
      })
    },
    toPage(name) {
      this.$router.push({
        name
      })
    },
    getUserVo() {
      getUserVo({}).then(res => {
        if(res.success) {
          this.user = res.data
        }
      })
    },
    getAccount() {
      getAccount({}).then(res => {
        if(res.success) {
          this.amount = !res.data ? 0 : res.data
        }
      })
    }
  },
  mounted() {
    this.getAccount()
    this.getUserVo()
  }
}
</script>
