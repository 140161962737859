<template>
  <div class="order_confirm_page_wrap" >
    <div class="address_wrap" v-if="addressData.userAddressId == null" @click="toAddress" >
      <div class="address_content" >
        <img src="./img/1.png" class="location_icon" />
        <div class="address_right" >
          <div class="address_info" >
            <div class="address_title" >添加收货地址</div>
            <div class="address_desc" >请添加您的收货地址及相关联系方式等信息内容</div>
          </div>
          <img src="./img/2.png" class="enter_icon" />
        </div>
      </div>
    </div>
    <div class="address_wrap" v-else @click="toAddress" >
      <div class="address_content" >
        <img src="./img/1.png" class="location_icon" />
        <div class="address_right" >
          <div class="address_info" >
            <div class="address_title" >{{ addressData.realName }} {{ addressData.phone }}</div>
            <div class="address_desc" >{{ addressData.province }}{{ addressData.city }}{{ addressData.district }}{{ addressData.detail }}</div>
          </div>
          <img src="./img/2.png" class="enter_icon" />
        </div>
      </div>
    </div>
    <div class="line" > </div>
    <div class="goods_list" >
      <div class="goods_item" v-for="(item,index) in cartData" :key="index" >
        <img :src="item.image" class="goods_img" />
        <div class="goods_right" >
          <div class="goods_title" >{{ item.storeName }}</div>
          <div class="goods_bottom" >
            <div class="goods_price" ><span>￥</span>{{ item.price }}</div>
            <div class="goods_number" >x{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="field_line" ></div>
    <div class="info_content" >
      <div>商品金额</div>
      <div style="color: #03CFAF;" >¥{{ (parseFloat(total) + parseFloat(discountAmount)).toFixed(2)  }}</div>
    </div>
<!--    <div class="field_line" ></div>-->
<!--    <div class="info_content" >-->
<!--      <div>折扣金额</div>-->
<!--      <div style="color: #FFCC00;" >-¥8.00</div>-->
<!--    </div>-->
    <div class="field_line" ></div>
    <div class="info_content" >
      <div>运费</div>
      <div>¥{{ freight }}</div>
    </div>
    <div class="field_line" ></div>
    <div class="info_content" >
      <div>支付方式</div>
      <div class="pay_wrap" >
        <img src="./img/3.png" class="wechat_icon" />
        微信
      </div>
    </div>
    <div class="field_line" ></div>
    <div class="remark_content" >
      <div>备注</div>
      <textarea placeholder="请输入备注" class="textarea_input" v-model="remark" />
    </div>

    <div class="bottom_wrap" >
      <div class="bottom_item" >
        实付：
        <div class="total_price" >
          <span>￥</span>{{ (parseFloat(total) + parseFloat(freight)).toFixed(2) }}
        </div>
      </div>

      <div class="coupon_text" v-if="discountAmount > 0" >
        已优惠：{{ discountAmount }}
      </div>

      <div class="bottom_item" >
<!--        <div class="freight_text" >
          邮费 <span class="freight_money" >￥{{ freight }}</span>
        </div>-->
        <van-button class="confirm_button" :loading="submitting" @click="createOrder" >确认支付</van-button>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {getShoppingFeeVo, getUserAddressVoDefault, addOrder, getSign, getPrice} from "@/api";
import wx from 'weixin-js-sdk'
import {
  Toast
} from 'vant'
export default {
  name: 'confirmOrder',
  data() {
    return {
      cartData: !sessionStorage.getItem('cartData') ? [] : JSON.parse(sessionStorage.getItem('cartData')),
      config: {
        count: 0,
        minFee: 0,
        maxFee: 0
      },
      total: 0,
      freight: 0,
      addressData: {},
      submitting: false,
      remark: '',
      discountAmount: 0
    }
  },
  methods: {
    toAddress() {
      this.$router.push({
        name: 'address',
        query: {
          type: '1'
        }
      })
    },
    createOrder() {
      const { addressData, remark } = this
      if(!addressData.userAddressId) {
        Toast('请先添加收货地址')
        return false
      }
      this.submitting = true
      let values = {
        address: addressData.province + addressData.city + addressData.district + addressData.detail,
        receiveName: addressData.realName,
        receiveTel: addressData.phone,
        remark,
        storeOrderGoodsCoList: this.getStoreOrderGoodsCoList()
      }
      console.log(values)
      addOrder({
        ...values
      }).then(res => {
        this.submitting = false
        if(res.success) {
          let data = JSON.parse(res.data)
          console.log(data)
          let values = {
            appId: data.appId,
            timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
            package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
            signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: data.paySign, // 支付签名
          }

          setTimeout(() => {
            wx.chooseWXPay({
              // appId: data.appId,
              // timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              // nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位
              // package: data.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
              // signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              // paySign: data.paySign, // 支付签名
              ...values,
              success: function (res) {
                Toast('支付成功')
              },
              cancel: function (res) {
                Toast('已取消支付');
              },
              fail: function (res) {
              },
              complete: function(res) {
              }
            });
          }, 0)
        }else {
          Toast(res.msg)
        }
      })
    },
    getStoreOrderGoodsCoList() {
      let storeOrderGoodsCoList = []
      this.cartData.map(item => {
        storeOrderGoodsCoList.push({
          storeProductId: item.storeProductId,
          num: item.num
        })
      })
      return storeOrderGoodsCoList
    },
    getTotal() {
      const { cartData, config } = this
      let total = 0
      let count = 0
      cartData.map(item => {
        // total += parseFloat(item.price) * item.num
        count += item.num
      })
      this.count = count
      // this.total = total.toFixed(2)
      this.freight = count > config.count ? config.maxFee : config.minFee
    },
    getShoppingFeeVo() {
      getShoppingFeeVo({}).then(res => {
        console.log('res:', res)
        if(res.success) {
          this.config = res.data
          this.getTotal()
        }
      })
    },
    getUserAddressVoDefault() {
      getUserAddressVoDefault({}).then(res => {
        if(res.success) {
          this.addressData = res.data
        }
      })
    },
    getSign() {
      getSign({ url: window.location.href }).then(res => {
        let params = res.data
        if(res.success) {
          wx.config({
            debug: false, // 生产环境需要关闭debug模式
            appId: params.appId, // appId通过微信服务号后台查看
            timestamp: params.timestamp, // 生成签名的时间戳
            nonceStr: params.nonceStr, // 生成签名的随机字符串
            signature: params.signature, // 签名
            jsApiList: [ // 需要调用的JS接口列表
              'chooseWXPay'
            ]
          })
        }
      })
    },
    getPrice() {
      let storeOrderGoodsCoList = []
      this.cartData.map(item => {
        storeOrderGoodsCoList.push({
          storeProductId: item.storeProductId,
          num: item.num
        })
      })
      getPrice({
        storeOrderGoodsCoList
      }).then(res => {
        console.log(res)
        if(res.success) {
          this.total = parseFloat(res.data.amount) - parseFloat(res.data.discountAmount)
          this.discountAmount = parseFloat(res.data.discountAmount)
        }
      })
    }
  },
  mounted() {
    this.getUserAddressVoDefault()
    let address = sessionStorage.getItem('address')
    if(address) {
      this.addressData = JSON.parse(address)
    }
    this.getShoppingFeeVo()
    this.getSign()
    this.getPrice()

  },
  created() {
  }
}
</script>
