<template>
  <div class="team_page_wrap" >
    <div class="statics_wrap" >
      <div>团队人数</div>
      <div>
        {{ count }}<span>（人）</span>
      </div>
    </div>
    <div class="search_wrap" >
      <div class="search_left" >
        <div>搜索</div>
        <input class="search_input" placeholder="请输入手机" v-model="data.phone" />
      </div>
      <img src="./img/18.png" class="search_icon" @click="reSearch" />
    </div>
    <div class="select_wrap" @click="show = true" >
      筛选：
      <div class="level_select_wrap" >
        {{ !data.lvl ? '级别' : columns[data.lvl].name }} <img src="./img/19.png" class="down_icon" />
      </div>
    </div>
    <div class="content_wrap" @scrol="handleScroll" >
      <van-empty description="暂无团队数据" v-if="list.length <= 0" />
      <div class="team_item" v-for="(item, index) in list" :key="index" >
        <div class="team_top" >
<!--          <div class="top_left" >-->
<!--            团队人数 <div class="count_text" >{{ count }}人</div>-->
<!--          </div>-->
          <div class="team_tag" v-if="item.lvl == 1" style="background: #18BCD0" >店主</div>
          <div class="team_tag" v-if="item.lvl == 2" style="background: #FF5897">合伙人</div>
          <div class="team_tag" v-if="item.lvl == 3"  style="background: #313131" >总监</div>
          <div class="team_tag" v-if="item.lvl == 4" >分公司</div>
        </div>
        <div class="team_info" >
          <div class="team_info_item" >
            <div>名称</div>
            <div>{{ item.name }}</div>
          </div>

          <div class="team_info_item" >
            <div>手机号</div>
            <div style="color: #707070" >{{ item.phone }}</div>
          </div>
        </div>
        <div class="recommend_wrap" >
          <div class="recommend_item" >
            <div>推荐人手机号</div>
            <div style="color: #03CFAF" >{{ item.parentPhone }}</div>
          </div>
          <div class="recommend_item" >
            <div>推荐人姓名</div>
            <div>{{ item.parentNickName }}</div>
          </div>
        </div>
        <div class="register_time" >
          <div>注册时间</div>
          <div style="color: #909090">{{ item.createTime }}</div>
        </div>
      </div>
    </div>

    <van-popup v-model="show" position="bottom">
      <van-picker
        title="级别"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="show = false"
        value-key="name"
      />
    </van-popup>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getTeam
} from '@/api'
export default {
  name: 'team',
  data() {
    return {
      show: false,
      columns: [
        { id: '', name: '全部' },
        { id: 1, name: '店主' },
        { id: 2, name: '合伙人' },
        { id: 3, name: '总监' },
        { id: 4, name: '分公司' },
      ],
      data: {
        lvl: '',
        name: '',
        phone: '',
        page: '1',
        pageSize: '10'
      },
      list: [],
      levelName: '',
      count: 0
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        this.page++
        this.getTeam()
      }
    },
    onConfirm(event) {
      this.data.lvl = event.id
      this.show = false
      this.reSearch()
    },
    reSearch(){
      this.data.page = 1
      this.list = []
      this.getTeam()
    },
    getTeam() {
      const { data } = this
      getTeam({ ...data }).then(res => {
        console.log(res)
        if(res.success) {
          this.count = res.count
          this.list = this.list.concat(res.data)
        }
      })
    },
  },
  mounted() {
    this.reSearch()
  }
}
</script>
