<template>
  <div class="account_log_page_wrap" @scroll="handleScroll" >
    <div class="content_wrap" style="width: 100%; height: 100%" >
      <div class="account_log_item" v-for="(item, index) in list" :key="index" >
        <div class="account_top" >
          <div>编号 {{ item.id }}</div>
          <div style="color: #03CFAF;" >{{ item.status == 3 || item.status == 2 || item.status == 0 ? '-' : '+' }}{{ item.drawMoney }}</div>
        </div>
        <div class="account_bottom" >
          <div class="account_tag" style="background: #03CFAF" v-if="item.status == 3">{{ item.statusStr }}</div>
          <div class="account_tag" style="background: #ffc000" v-if="item.status == 0" >{{ item.statusStr }}</div>
          <div class="account_tag" style="background: #ffc000" v-if="item.status == 2" >{{ item.statusStr }}</div>
          <div class="account_tag" style="background: #ff0000" v-if="item.status == 1" >{{ item.statusStr }}</div>
          <div class="account_tag" style="background: #ff0000" v-if="item.status == 4" >{{ item.statusStr }}</div>
          <div>{{ item.createTime }}</div>
        </div>
      </div>

      <van-empty description="暂无提现记录" v-if="list.length <= 0" />

    </div>

    <!--    <div class="account_log_item" >
          <div class="account_top" >
            <div>订单号 CEG0908546894789</div>
            <div style="color: #62BFDA;" >+899.00</div>
          </div>
          <div class="account_bottom" >
            <div class="account_tag" style="background: #62BFDA">平级奖励</div>
            <div>2024-08-22  10:00:23</div>
          </div>
        </div>

        <div class="account_log_item" >
          <div class="account_top" >
            <div>订单号 CEG0908546894789</div>
            <div style="color: #FF78AB;" >+899.00</div>
          </div>
          <div class="account_bottom" >
            <div class="account_tag" style="background: #FF78AB">提现</div>
            <div>2024-08-22  10:00:23</div>
          </div>
        </div>-->
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCashList
} from '@/api'
export default {
  name: 'accountLog',
  data() {
    return {
      page: 1,
      pageSize: 10,
      list: []
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        this.page++
        this.getCashList()
      }
    },
    getCashList() {
      const { page, pageSize } = this
      getCashList({ page, pageSize }).then(res => {
        console.log(res)
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    }
  },
  mounted() {
    this.getCashList()
  }
}
</script>
